define(
  'toolkit/modules/video-overlay',
  ['toolkit/jquery', 'toolkit/modules/overlay'],

  function ($, Overlay) {
    // For a super basic little video overlay
    var VideoOverlay = {
      // You can change the selector if you need to, but probably best to just use this data attribute
      selector: '[data-video-overlay]'
    };

    VideoOverlay.init = function () {
      var self = this;
      this.triggers = document.querySelectorAll(this.selector);

      for (var i = this.triggers.length - 1; i >= 0; i--) {
        this.triggers[i].addEventListener('click', function (e) {
          e.preventDefault();
          self._showOverlay(this);
        });
      }
    };

    VideoOverlay._showOverlay = function (link) {
      if (!this.overlay) {
        // creating an overlay
        var $overlay = $('<div id="video-overlay"></div>');
        $('body').append($overlay);
        this.overlay = new Overlay($overlay, { full_screen: true });

        this.overlay.$el.on('overlay.afterHide', function () {
          this.overlay.$el.find('.responsive-video').remove();
        }.bind(this));
      }

      this.overlay.$el.html('<div class="video-container"><div class="responsive-video"><iframe width="560" height="315" src="' + link.getAttribute('href') + '?rel=0" frameborder="0" allowfullscreen></iframe></div></div>');
      this.overlay.show();
    };

    return VideoOverlay;
  }
);
