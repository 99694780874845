define('toolkit/modules/banner', ['toolkit/jquery'], function ($) {
  // TODO: clean this up, figure out why we have showLoading and showSaving, remove repeated code

  var $banner;
  var timer;
  var _onConfirm; // stores the user supplied callback functions for Banner.confirm
  var _onCancel;

  var firstErrorTimer;
  // Probably should be defaulted to true, but I didn't want to change how any of the
  // forms are currently working.
  var isFirstErrorOnly = false;
  var firstErrorTimeout = 100;
  var isFirstErrorTriggered = false;

  function generateMessage (message, closeButton, autoCloseTime, classes, closeCallback) {
    if (Banner.isShown()) {
      Banner.close();
      clearTimeout(timer);
      if (typeof classes === 'undefined') {
        classes = ' new_error';
      } else {
        classes += ' new_error';
      }
    }
    var html = message;
    Banner.classes = classes;
    Banner.closeCallback = closeCallback;
    if (closeButton !== false) {
      // pass `false` as second param if you don't want the close button
      html += ' <button type="button" class="button close">Close</button>';
    }
    if (Banner.classes) $banner.addClass(classes);
    $banner.on('webkitAnimationEnd oanimationend msAnimationEnd animationend',
      function (e) {
        $banner.removeClass('new_error');
      });
    $banner.html(html);
    $banner.addClass('loading').removeClass('error'); // TODO: should this call Banner.show()?

    if (autoCloseTime) {
      timer = setTimeout(function () {
        Banner.close();
      }, autoCloseTime);
    }
  }

  var Banner = {
    useFirstErrorOnly: function () {
      isFirstErrorOnly = true;
    },

    showError: function (message, closeButton, autoCloseTime, classes, closeCallback) {
      Banner.showMessage(message, closeButton, autoCloseTime, classes, closeCallback);
      $banner.addClass('error');
    },

    showMessage: function (message, closeButton, autoCloseTime, classes, closeCallback) {
      console.log('banner:showMessage', message);
      if (isFirstErrorOnly) {
        if (!isFirstErrorTriggered) {
          generateMessage(message, closeButton, autoCloseTime, classes, closeCallback);
          isFirstErrorTriggered = true;
          firstErrorTimer = setTimeout(function () {
            isFirstErrorTriggered = false;
          }, firstErrorTimeout);
        }
      } else {
        generateMessage(message, closeButton, autoCloseTime, classes, closeCallback);
      }
    },

    showLoading: function (message) {
      // TODO: flesh this out for real
      Banner.showSaving(message);
    },

    showSaving: function (message) {
      console.log('banner:showSaving', message);
      $banner.removeClass('loading error'); // TODO: abstractify this
      $banner.html('<span class="mypage--loader">' + message + '</span>');
      $banner.addClass('loading'); // TODO: should this call Banner.show()?
    },

    confirm: function (message, onConfirm, onCancel, confirm_label, cancel_label) {
      confirm_label = confirm_label || 'OK';
      cancel_label = cancel_label || 'Cancel';
      $banner.removeClass('loading error'); // TODO: abstractify this
      // save the callback functions
      _onConfirm = onConfirm;
      _onCancel = onCancel;
      message += '<span class="banner__controls"><button type="button" class="button confirm">' + confirm_label + '</button>';
      message += ' <button type="button" class="button close">' + cancel_label + '</button></span>';
      Banner.showMessage(message, false);
    },

    show: function () {
      $banner.addClass('loading');
    },

    close: function () {
      if (this.closeCallback) this.closeCallback();
      if (this.classes) $banner.removeClass(this.classes);
      $banner.removeClass('loading');
    },

    isShown: function () {
      return $banner.hasClass('loading');
    }
  };

  $(function init_banner () {
    console.log('initializing banner');
    // TODO: if you change the class name for banners, update overlay.js too
    // I would really like to change this to something like TK-banner, but it is going
    // to cause a waterfall effect throughout website-ruby - AS
    $banner = $('.mypage--banner');
    $banner.on('webkitAnimationEnd oanimationend msAnimationEnd animationend',
      function () {
        $(this).removeClass('new_error');
      }
    );
    if (!$banner.length) {
      console.log('creating banner element');
      $banner = $('<div class="mypage--banner"></div>').insertBefore('[role=main]');
      console.log('banner created', $banner);
    }

    $banner.on('click', '.close', function (click) {
      click.preventDefault();
      click.stopPropagation();
      Banner.close();

      if (_onConfirm) {
        _onConfirm = null; // release the callback
      }
      if (_onCancel) {
        _onCancel();
        _onCancel = null;
      }
    });

    $banner.on('click', '.confirm', function (click) {
      click.preventDefault();
      click.stopPropagation();

      console.log('confirmed');

      Banner.close();

      // call and release callbacks
      if (_onConfirm) {
        _onConfirm();
        _onConfirm = null;
      }
      if (_onCancel) {
        _onCancel = null;
      }
    });
  });

  return Banner;
});
